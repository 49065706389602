/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'short_logo': {
    width: 111,
    height: 140,
    viewBox: '0 0 111 140',
    data: '<path pid="0" d="M10.68 0H.66v140h10.02V0zM100.641 0v64.986h-78.87v9.958h78.87V140h10.019V0h-10.019z" _fill="#fff"/>'
  }
})
